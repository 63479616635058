import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import { UrlConstants } from "@/constants/url.constants";
import store from "../index";
import { iItemMenu } from "../../interfaces/menu";
import { menu } from "../../constants/menu";
import { iProfileUserSession } from "../../interfaces/users";
import { IPermissionGroupProfile } from "../../interfaces/profile";

// notifications options
export interface iNotification {
  color?: string;
  isOpen: boolean;
  message: string;
  timeout?: number;
}

export interface iCurrencyConfig {
  decimalDigits: number;
  decimalSeparator: string;
  thousandsSeparator: string;
  currencySymbol: string;
  label: string;
  currencySymbolNumberOfSpaces: number;
  currencySymbolPosition: string;
}

export interface iMain {
  isOpenDrawer: boolean;
  title: string;
  notification: iNotification;
  isOpenDrawerFront: boolean;
  currency: iCurrencyConfig;
  queryParams: Record<string, string | number>;
  timeZone: string;
  menu: iItemMenu[];
}

@Module({
  name: "Main",
  store,
  dynamic: true,
})
export class Main extends VuexModule implements iMain {
  public queryParams: Record<string, string | number> = {};
  public isOpenDrawer = true;
  public isOpenDrawerFront = false;
  public BASE_URL = UrlConstants.BASE_URL_PUBLIC;
  public currency: iCurrencyConfig = {
    label: "CLP",
    decimalDigits: 0,
    decimalSeparator: ",",
    thousandsSeparator: ".",
    currencySymbol: "$",
    currencySymbolNumberOfSpaces: 0,
    currencySymbolPosition: "left",
  };
  public timeZone = "America/Santiago";
  public title = "";
  public notification: iNotification = {
    color: "green",
    timeout: 2000,
    message: "",
    isOpen: false,
  };
  public menu = menu;

  @Mutation
  private SET_TIMEZONE(value: string) {
    this.timeZone = value;
  }

  @Mutation
  private SET_TITLE(value = "") {
    this.title = value;
  }

  @Mutation
  private SET_QUERY_PARAMS(params: Record<string, string | number>) {
    this.queryParams = params;
  }

  @Mutation
  private SET_DRAWER(value = false) {
    this.isOpenDrawer = value;
  }

  @Mutation
  private SET_DRAWER_FRONT(value: boolean) {
    this.isOpenDrawerFront = value;
  }

  @Mutation
  private SET_NOTIFICACION(value: iNotification) {
    this.notification = {
      color: value.color || "green",
      isOpen: value.isOpen,
      message: value.message,
      timeout: value.timeout || 2000,
    };
  }

  @Mutation
  private SET_MENU(value: iItemMenu[]) {
    this.menu = value;
  }

  @Action
  setTimezone(value: string) {
    this.SET_TIMEZONE(value);
  }

  @Action
  setQueryParams(params: Record<string, string | number>) {
    this.SET_QUERY_PARAMS(params);
  }

  @Action
  toggleDrawer() {
    this.SET_DRAWER(!this.isOpenDrawer);
  }

  @Action
  setDrawer(value = false) {
    this.SET_DRAWER(value);
  }

  @Action
  toggleDrawerFront() {
    this.SET_DRAWER_FRONT(!this.isOpenDrawerFront);
  }

  @Action
  setDrawerFront(value: boolean) {
    this.SET_DRAWER_FRONT(value);
  }

  @Action
  setTitleApp(value: string) {
    this.SET_TITLE(value);
  }

  @Action
  setNotification(dataNotification: iNotification) {
    this.SET_NOTIFICACION(dataNotification);
  }

  @Action
  updateMenu(user: iProfileUserSession | null) {
    if (!user) {
      this.SET_MENU([]);
      return;
    }
    if (user.isSuper) {
      this.SET_MENU(menu);
      return;
    }
    const permissions = user.profile.permissions as IPermissionGroupProfile[];
    if (!user.isSuper) {
      const menuWithoutSuper = this.menu
        .filter((item) => !item.isSuper)
        .map((item) => {
          return {
            ...item,
            items: item.items.filter((i) => !i.isSuper),
          };
        })
        .map((item) => {
          return {
            ...item,
            items: item.items.filter(({ name }) => {
              if (name === "trips-create") {
                const exist = permissions.find(
                  ({ page, actions }) =>
                    page === 'trips' &&
                    actions.find((action) => action.action === "WRITE")
                );
                return exist;
              } else {
                const exist = permissions.find(
                  ({ page, actions }) =>
                    page === name &&
                    actions.find((action) => action.action === "READ")
                );
                return exist;
              }
            }),
          };
        })
        .filter((item) => item.items.length > 0);
      this.SET_MENU(menuWithoutSuper);
      return;
    }
  }
}

export const MainModule = getModule(Main);
