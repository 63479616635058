import { iItemMenu } from "../interfaces/menu";

export const menu : iItemMenu[]= [
  {
    key: 0,
    name: "Viajes",
    items: [
      {
        icon: "mdi-bus",
        text: "Listar viajes",
        link: "/trips",
        name: 'trips',
        exact : true
      },
      {
        icon: "mdi-bus",
        text: "Crear viaje",
        link: "/trips/create",
        name: 'trips-create',
        exact : true
      },
    ],
  },
  {
    key: 1,
    name: "Rutas",
    items: [
      {
        icon: "mdi-sign-direction",
        text: "Listar rutas",
        link: "/routes",
        name: 'routes',
        exact : true
      },
      {
        icon: "mdi-sign-direction",
        text: "Listar regiones y comunas",
        link: "/routes/regions",
        name: 'routes',
        exact : true
      },
    ],
  },
  {
    key: 2,
    name: "Ususarios",
    items: [
      {
        icon: "mdi-domain",
        text: "Compañias",
        link: "/company",
        name: 'company',
      },
      {
        icon: "mdi-account-hard-hat-outline",
        text: "Conductores",
        link: "/drivers",
        name: 'drivers',
      },
      {
        icon: "mdi-account-cash-outline",
        text: "Clientes",
        link: "/customers",
        name: 'customers',
      },
      {
        icon: "mdi-account",
        text: "Proveedores",
        link: "/providers",
        name: 'providers',
      },
    ],
  },
  {
    key: 4,
    name: "Vehículos",
    items: [
      {
        icon: "mdi-car-estate",
        text: "Vehículos",
        name: 'vehicles',
        link: "/vehicles",
      },
      {
        icon: "mdi-car-select",
        text: "Marcas",
        link: "/brands",
        name: 'brands',
      },
      {
        icon : 'mdi-car-cog',
        text: 'Repuestos',
        name: 'parts',
        isSuper : true,
        link : '/vehicle-parts'
      },
      {
        icon : 'mdi-book-clock',
        text: 'Programación de Mant.',
        name: 'maitenances',
        isSuper : true,
        link : '/maintenance-schedule'
      }
    ],
  },
  {
    key: 5,
    name: "Reporte",
    items: [
      {
        icon: "mdi-chart-bar",
        text: "Liquidaciones",
        link: "/settlements",
        name: 'settlements',
      },
      {
        icon: "mdi-chart-bar",
        text: "Facturación",
        link: "/billing",
        name: 'billing',
      },
      {
        icon: "mdi-currency-usd",
        text: "Gastos",
        link: "/bills",
        name: 'bills',
        exact : true
      },
      {
        icon: "mdi-currency-usd",
        text: "Gastos por vehículo",
        link: "/bills/vehicles",
        name: 'bills-vehicle',
        exact : true
      },
      {
        icon: "mdi-currency-usd",
        text: "Ingresos",
        link: "/income",
        name: 'income',
      },
    ],
  },
  {
    key: 6,
    name: 'Administración',
    items : [
      {
        icon : 'mdi-security',
        text: 'Administradores',
        link: '/admins',
        isSuper : true,
        name: 'admins',
      },
      {
        icon : 'mdi-shield-account',
        text : 'Perfiles',
        link : '/profiles',
        isSuper : true,
        name: 'profiles',
      }
    ]
  }
];
