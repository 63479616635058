import { Route } from "vue-router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { AuthModule } from "../store/modules/Auth";
import { IPermissionGroupProfile } from "@/interfaces/profile/index";

import router from "../router";

NProgress.configure({ showSpinner: false });

const whiteList = ["dashboard", "login"];

/* eslint-disable */

router.beforeEach(async (to: Route, _: Route, next: any) => {
  if (to.name && whiteList.includes(to.name)) {
    next();
  } else {
    NProgress.start();
    try {
      await AuthModule.getUser();
      const user = AuthModule.currentUser;
      if (!user) {
        throw new Error("Not found user");
      }
      if (user.isSuper) {
        next();
        return;
      }
      if (!user.profile) {
        next(`/dashboard`);
        return;
      }
      let name = to.name;
      const permissions = user.profile.permissions as IPermissionGroupProfile[];
      if (name === "regions") {
        name = "routes";
      }
      if (name === "trips-create") {
        const existePermission = permissions.find(
          ({ page }) => page === "trips"
        );
        if (
          !existePermission ||
          !existePermission.actions.find(({ action }) => action === "WRITE")
        ) {
          NProgress.done();
          next("/dashboard");
        } else {
          NProgress.done();
          next();
        }
        return;
      }
      const existePermission = permissions.find(({ page }) => page === name);
      if (
        !existePermission ||
        !existePermission.actions.find(({ action }) => action === "READ")
      ) {
        NProgress.done();
        next("/dashboard");
      } else {
        NProgress.done();
        next();
      }
    } catch (error) {
      AuthModule.resetToken();
      next(`/login?redirect=${to.path}`);
      NProgress.done();
    }
  }
});

router.afterEach((to: Route) => {
  // Finish progress bar
  // hack: https://github.com/PanJiaChen/vue-element-admin/pull/2939
  NProgress.done();
});
